<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Transport List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />
      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Mode of Transport')" prop="modeOfTransport">
            <el-select v-model="searchForm.modeOfTransport" placeholder="Select">
              <el-option
                v-for="mode in MODE_OF_TRANSPORT_VARIABLES"
                :key="mode.code"
                :label="mode.label"
                :value="mode.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
            <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
            <el-select v-model="searchForm.processTypes" :class="{multiple_selected: multipleSelect(searchForm.processTypes)}" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="process in processes"
                :key="process.name"
                :label="process.name"
                :value="process.processType"
              >
                <span>{{process.name}}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
            <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
                <el-input type="text" v-model="searchForm.exCustomInvoiceNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
              <template v-if="searchForm.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT">
                <el-form-item :label="$t('Label.Flight No')" prop="flightNo">
                  <el-input type="text" v-model="searchForm.departureFlightNo" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.AWB No')" prop="awbNo">
                  <el-input type="text" v-model="searchForm.awbNo" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.AWB Date')" prop="awbDate">
                  <el-date-picker
                    v-model="searchForm.awbDate"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </template >
              <template v-else>
                <el-form-item :label="$t('Label.Vessel Name')" prop="vesselName">
                  <el-input type="text" v-model="searchForm.nameOfVessel" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.B/L No')" prop="blNo">
                  <el-input type="text" v-model="searchForm.blNo" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.B/L Date')" prop="blDate">
                  <el-date-picker
                    v-model="searchForm.blDate"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </template >
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Shipper')" prop="shipper">
                <el-input type="text" v-model="searchForm.shipperName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="mainGoods">
                <el-input type="text" v-model="searchForm.transportationGroupOfGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <template v-if="searchForm.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT">
                <el-form-item :label="$t('Label.Departure Time')" prop="departureTime">
                  <el-date-picker
                    v-model="searchForm.departureTime"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.Departure port')" prop="departurePort">
                  <el-select v-model="searchForm.departureDoorAirport" placeholder="Select" clearable>
                    <el-option
                      v-for="type in TYPE_OF_SERVICE_FCL_LCL_VARIABLES"
                      :key="type.code"
                      :label="type.label"
                      :value="type.code">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('Label.Arrival Time')" prop="arrivalTime">
                  <el-date-picker
                    v-model="searchForm.arrivalTime"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.Arrival port')" prop="arrivalPort">
                  <el-select v-model="searchForm.arrivalDoorAirport" placeholder="Select" clearable>
                    <el-option
                      v-for="type in TYPE_OF_SERVICE_FCL_LCL_VARIABLES"
                      :key="type.code"
                      :label="type.label"
                      :value="type.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template >
              <template v-else>
                <el-form-item :label="$t('Label.Cut Date')" prop="cutDate">
                  <el-date-picker
                    v-model="searchForm.cutDate"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.ETD')" prop="loadingPortEtd">
                  <el-date-picker
                    v-model="searchForm.loadingPortEtd"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.Port of Loading')" prop="portOfLoadingName">
                  <el-input type="text" v-model="searchForm.portOfLoadingName" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.ETA')" prop="dischargingEta">
                  <el-date-picker
                    v-model="searchForm.dischargingEta"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.Port of Discharge')" prop="portOfShipmentName">
                  <el-input type="text" v-model="searchForm.portOfDischargingName" placeholder="Enter keyword"></el-input>
                </el-form-item>
              </template >
              <el-form-item :label="$t('Label.Status')" prop="processProgressStatus" style="margin-right: 40px">
                <el-select v-model="searchForm.processProgressStatus" :class="{multiple_selected: multipleSelect(searchForm.processProgressStatus)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system v-if="modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT" :schemas="schemasAir" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />

      <tw-table-system v-else-if="modeOfTransport === MODE_OF_TRANSPORT['VESSEL(BULK)']" :schemas="schemasBulk" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />

      <tw-table-system v-else :schemas="schemasContainer" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { TRANSPORTATION_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, ENTITY_TYPE, MODE_OF_TRANSPORT, MODE_OF_TRANSPORT_VARIABLES, TYPE_OF_SERVICE_FCL_LCL_VARIABLES, INITIAL_VIEW_FLG } from 'lib-tw-common';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm } from '@/utils/searchUtil.js';
import schemasAir from '@/dictionaries/listTransportAirSchema.json';
import schemasBulk from '@/dictionaries/listTransportBulkSchema.json';
import schemasContainer from '@/dictionaries/listTransportContainerSchema.json';

const ENTITY_NAME = 'TRANSPORTATION'

export default {
  name: 'ListTransport',
  mixins: [mixinEntityList, collapseTransition, mixinEntity],
  data() {
    return {
      entityCode: ENTITY_TYPE.TRANSPORTATION,
      modeOfTransport: null,
      schemasAir: schemasAir,
      schemasBulk: schemasBulk,
      schemasContainer: schemasContainer,
      schemas: null,
      items: null,
      itemsAir: null,
      searchForm: {
        modeOfTransport: MODE_OF_TRANSPORT['VESSEL(CONTAINER)'],
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        exCustomInvoiceNo: '',
        fromSectionIds: [],
        toSectionIds: [],
        departureFlightNo: '',
        nameOfVessel: '',
        blNo: '',
        blDate: '',
        shipperName: '',
        transportationGroupOfGoods: '',
        departureTime: '',
        departureDoorAirport: '',
        arrivalTime: '',
        arrivalDoorAirport: '',
        cutDate: '',
        loadingPortEtd: '',
        portOfLoadingName: '',
        dischargingEta: '',
        portOfDischargingName: '',
        processProgressStatus: [],
        processTypes: [],
        processUserMemo: "",
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      MODE_OF_TRANSPORT: MODE_OF_TRANSPORT,
      MODE_OF_TRANSPORT_VARIABLES: MODE_OF_TRANSPORT_VARIABLES,
      TYPE_OF_SERVICE_FCL_LCL_VARIABLES: TYPE_OF_SERVICE_FCL_LCL_VARIABLES,
      lastQuery: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Transport List')},
      ];
    },
  },
  created() {
    const queries = this.$store.getters.getQueries(ENTITY_NAME);
    if (queries) {
      this.searchForm = queries;
    }
    this.modeOfTransport = this.searchForm.modeOfTransport;
    this.onModeChange();
    this.fetch();
    this.processes = this.getProcessesByEntity(ENTITY_NAME)
  },
  methods: {
    onModeChange() {
      this.modeOfTransport = this.searchForm.modeOfTransport;
      if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(CONTAINER)']) {
        this.schemas = schemasContainer;
      } else if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(BULK)']) {
        this.schemas = schemasBulk;
      } else if (this.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT) {
        this.schemas = schemasAir;
      }
    },
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      let queries;
      if (this.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT) {
        queries = {
          tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
          tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
          tradingName: f.tradingName,
          flowName: f.flowName,
          invoiceNo: f.exCustomInvoiceNo,
          fromSectionIds: f.fromSectionIds,
          toSectionIds: f.toSectionIds,
          flightNo: f.departureFlightNo,
          blNo: f.awbNo,
          blStartDate: this.getYMDStartDate(f.awbDate),
          blEndDate: this.getYMDEndDate(f.awbDate),
          shipper: f.shipperName,
          mainGoods: f.transportationGroupOfGoods,
          departureStartDate: this.getYMDStartDateTime(f.departureTime),
          departureEndDate: this.getYMDEndDateTime(f.departureTime),
          departurePort: f.departureDoorAirport,
          arrivalStartDate: this.getYMDStartDateTime(f.arrivalTime),
          arrivalEndDate: this.getYMDEndDateTime(f.arrivalTime),
          arrivalPort: f.arrivalDoorAirport,
          processProgressStatus: this.getProcessProgressStatus(f.processProgressStatus),
          processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
          processUserMemo: f.processUserMemo,
        };
      } else {
        queries = {
          tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
          tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
          tradingName: f.tradingName,
          flowName: f.flowName,
          invoiceNo: f.exCustomInvoiceNo,
          fromSectionIds: f.fromSectionIds,
          toSectionIds: f.toSectionIds,
          vesselName: f.nameOfVessel,
          blNo: f.blNo,
          blStartDate: this.getYMDStartDate(f.blDate),
          blEndDate: this.getYMDEndDate(f.blDate),
          shipper: f.shipperName,
          mainGoods: f.transportationGroupOfGoods,
          cutStartDate: this.getYMDStartDate(f.cutDate),
          cutEndDate: this.getYMDEndDate(f.cutDate),
          etdStartDate: this.getYMDStartDate(f.loadingPortEtd),
          etdEndDate: this.getYMDEndDate(f.loadingPortEtd),
          portOfLoadingName: f.portOfLoadingName,
          etaStartDate: this.getYMDStartDate(f.dischargingEta),
          etaEndDate: this.getYMDEndDate(f.dischargingEta),
          portOfShipmentName: f.portOfDischargingName,
          processProgressStatus: this.getProcessProgressStatus(f.processProgressStatus),
          processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
          processUserMemo: f.processUserMemo,
        };
      }

      const initialFlag = f.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(CONTAINER)'] ? this.initialFlag(queries) : INITIAL_VIEW_FLG.OFF;
      return {
        ...queries,
        initialFlag: initialFlag,
        modeOfTransport: initialFlag ? null : f.modeOfTransport,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_tp_1 輸送一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/transportations/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(CONTAINER)'] && res.containers) {
          this.items = res.containers;
        } else if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(BULK)'] && res.bulks) {
          this.items = res.bulks;
        } else if (this.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT && res.airs) {
          this.items = res.airs;
        } else {
          this.items = [];
        }
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.onModeChange();
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [TRANSPORTATION_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_tp_1 輸送一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/transportations/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(CONTAINER)'] && res.containers) {
            resolve(res.containers);
          } else if (this.modeOfTransport === MODE_OF_TRANSPORT['VESSEL(BULK)'] && res.bulks) {
            resolve(res.bulks);
          } else if (this.modeOfTransport === MODE_OF_TRANSPORT.AIRCRAFT && res.airs) {
            resolve(res.airs);
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
